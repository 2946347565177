<template>
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="fullPage"></loading>
    <form data-vv-scope="oportunidadeForm" ref="form" method="post">
      <div class="row justify-content-md-center p-3">

        <div class="col-12 col-md-12">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12 mb-4">
                  <h5 class="box-title mb-3">{{ $t('Dados gerais') }}</h5>
                </div>
                <!-- Número Oportunidade -->
                <custom-input
                  v-model="formData.numeroOportunidade"
                  name="formData.numeroOportunidade"
                  :label="this.$i18n.t('form.oportunidade.numeroOportunidade')"
                  type="text"
                  disabled
                  v-if="isEdit"
                  rootClassName="col-sm-4 form-group mb-4"
                  inputClassName="md-form-control"
                  v-validate="{ required: false }"
                  :error="submitted && errors.first('formData.numeroOportunidade') ? errors.first('formData.numeroOportunidade') : ''">
                </custom-input>

                <!-- Cliente -->
                <cliente-field
                  :grey-field="false"
                  name="formData.idCliente"
                  v-model="formData.idCliente"
                  rootClassName="col-12 col-lg-12 form-group mb-4"
                  v-validate="'required'"
                  :error="errors.first('formData.idCliente')"
                />

                <!--  Modalidade  -->
                <div class="col-12 col-md-2 form-group mb-4">
                  <label>{{  $t('form.proposal.modality')  }}
                  </label>
                  <v-select
                    label="nome" name="formData.modalidadeProcesso" :clearable="false" :searchable="false"
                    @input="onSelectModality"
                    v-model="formData.modalidadeProcesso" :options="modalityList"
                    v-validate="{ required_object: true }"
                  >
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ option.nome }}
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{ option.nome }}
                      </div>
                    </template>
                  </v-select>
                  <transition name="slide" mode="">
                    <small v-if="errors.first('formData.modalidadeProcesso')" class="royalc-error-field">{{ errors.first('formData.modalidadeProcesso') }}</small>
                  </transition>
                </div>

                <!--  Tipo Carga  -->
                <tipo-carga-field
                  v-show="showTipoCarga"
                  :grey-field="false"
                  root-class-name="col-12 col-md-2 form-group mb-4"
                  v-model="formData.tipoCarga"
                  :modalidade-processo="modalidadeProcessoId"
                  name="formData.tipoCarga"
                  v-validate="{ required: showTipoCarga }"
                  :error="errors.first('formData.tipoCarga')"
                  :disabled="originDestinationDisabled"
                />

                <!-- Origem -->
                <origin-destination-field
                  :grey-field="false"
                  root-class-name="col-12 col-md-4 form-group mb-4"
                  :class="{ 'col-md-5': !showTipoCarga }"
                  v-model="formData.idorigem"
                  :type="getOriginDestinationType(formData.modalidadeProcesso)"
                  name="formData.idorigem"
                  v-validate="'required'"
                  :error="errors.first('formData.idorigem')"
                  :disabled="originDestinationDisabled"
                />

                <!-- Destino -->
                <origin-destination-field
                  label="form.oportunidade.destino"
                  :grey-field="false"
                  root-class-name="col-12 col-md-4 form-group mb-4"
                  :class="{ 'col-md-5': !showTipoCarga }"
                  v-model="formData.iddestino"
                  :type="getOriginDestinationType(formData.modalidadeProcesso)"
                  name="formData.iddestino"
                  v-validate="'required'"
                  :error="errors.first('formData.iddestino')"
                  :disabled="originDestinationDisabled"
                />

                <!-- Mercadoria -->
                <mercadoria-field
                  label="form.oportunidade.mercadoria"
                  :grey-field="false"
                  root-class-name="col-12 col-md-6 form-group mb-4"
                  v-model="formData.idMercadoria"
                  name="formData.idMercadoria"
                  v-validate="'required'"
                  :error="errors.first('formData.idMercadoria')"
                />

                <div class="col-12 mb-4">
                  <h5 class="box-title mb-3">{{ $t('Volume') }}</h5>
                </div>
                <!-- Volume Total -->
                <custom-input
                  v-model="formData.volumeTotal"
                  name="formData.volumeTotal"
                  :label="this.$i18n.t('form.oportunidade.volumeTotal')"
                  type="text"
                  rootClassName="col-sm-4 form-group mb-4"
                  inputClassName="md-form-control"
                  v-validate="{ required: true }"
                  :v-mask="{ 'allowPlus':true, 'alias': 'currency', 'prefix': '', 'groupSeparator': '.', 'radixPoint':','}"
                  :error="submitted && errors.first('formData.volumeTotal') ? errors.first('formData.volumeTotal') : ''">
                </custom-input>

                <!-- Volume Potencial -->
                <custom-input
                  v-model="formData.volumePotencial"
                  name="formData.volumePotencial"
                  :label="this.$i18n.t('form.oportunidade.volumePotencial')"
                  type="text"
                  rootClassName="col-sm-4 form-group mb-4"
                  inputClassName="md-form-control"
                  v-validate="{ required: true }"
                  :v-mask="{ 'allowPlus':true, 'alias': 'currency', 'prefix': '', 'groupSeparator': '.', 'radixPoint':','}"
                  :error="submitted && errors.first('formData.volumePotencial') ? errors.first('formData.volumePotencial') : ''">
                </custom-input>

                <!-- Unidade Medida -->
                <unidade-medida-field
                  label="form.oportunidade.idunidadeMedidaVolume"
                  :grey-field="false"
                  :modalidade-processo="modalidadeProcessoId"
                  :tipo-carga="tipoCargaId"
                  root-class-name="col-12 col-md-4 form-group mb-4"
                  v-model="formData.idunidadeMedidaVolume"
                  name="formData.idunidadeMedidaVolume"
                  v-validate="'required'"
                  :error="errors.first('formData.idunidadeMedidaVolume')"
                  :disabled="unidadeMedidaDisabled"
                />

                <div class="col-12 mb-4">
                  <h5 class="box-title mb-3">{{ $t('Frequência') }}</h5>
                </div>

                <!-- Frequencia -->
                <frequencia-field
                  label="form.oportunidade.frequencia"
                  :grey-field="false"
                  root-class-name="col-12 col-md-6 form-group mb-4"
                  v-model="formData.idfrequencia"
                  name="formData.idfrequencia"
                  v-validate="'required'"
                  :error="errors.first('formData.idfrequencia')"
                />

                <div class="col-12 mb-4">
                  <h5 class="box-title mb-3">{{ $t('Target') }}</h5>
                </div>

                <!-- Moeda-->
                <moeda-field
                  name="formData.idmoeda"
                  v-model="formData.idmoeda"
                  rootClassName="col-lg-4 col-md-8 form-group mb-4"
                  v-validate="'required'"
                  :error="errors.first('formData.idmoeda')"
                />

                <!-- Target -->
                <custom-input
                  v-model="formData.target"
                  name="formData.target"
                  :label="this.$i18n.t('form.oportunidade.target')"
                  type="text"
                  rootClassName="col-sm-4 form-group mb-4"
                  inputClassName="md-form-control"
                  v-validate="{ required: true }"
                  :v-mask="{ 'allowPlus':true, 'alias': 'currency', 'prefix': '', 'groupSeparator': '.', 'radixPoint':','}"
                  :error="submitted && errors.first('formData.target') ? errors.first('formData.target') : ''">
                </custom-input>

                <!-- Unidade Medida Target -->
                <unidade-medida-field
                  label="form.oportunidade.idunidadeMedidaTarget"
                  :grey-field="false"
                  :modalidade-processo="modalidadeProcessoId"
                  :tipo-carga="tipoCargaId"
                  root-class-name="col-12 col-md-4 form-group mb-4"
                  v-model="formData.idunidadeMedidaTarget"
                  name="formData.idunidadeMedidaTarget"
                  v-validate="'required'"
                  :error="errors.first('formData.idunidadeMedidaTarget')"
                  :disabled="unidadeMedidaDisabled"
                />

                <!-- Validade -->
                <div class="col-12 col-md-6">
                  <label>{{ this.$i18n.t('form.oportunidade.validade_target') }}</label>
                  <md-datepicker name="formData.dataValidade" v-model="formData.dataValidade"
                    md-immediately v-validate="{ required: true }"/>
                  <transition name="slide" mode="">
                    <small v-if="errors.first('formData.dataValidade')"
                      class="royalc-error-field">{{ errors.first('formData.dataValidade') }}</small>
                  </transition>
                </div>

                <!-- Observação -->
                <div class="col-12 col-md-12">
                  <custom-textarea
                    v-model="formData.observacao"
                    name="formData.observacao"
                    label="Observações"
                    type="text"
                    rootClassName="md-form mb-0"
                    inputClassName="md-form-control"
                    :rows="10"
                    v-validate="{ required: false }"
                    background="#ffffff"
                    :error="errors.first('formData.observacao')">
                  </custom-textarea>
                </div>

                <!-- Solicitante -->
                <funcionario-field
                  :grey-field="false"
                  label="form.oportunidade.solicitante"
                  name="formData.solicitante"
                  v-model="formData.solicitante"
                  rootClassName="col-12 col-lg-12 form-group mb-4"
                  v-validate="'required'"
                  :error="errors.first('formData.solicitante')"
                />
              </div>
              <!-- Histórico-->
              <div class="row" v-if="historico && historico.length > 0">
                <div class="col-12 mb-1">
                  <h5 class="box-title mb-3">{{ $t('Histórico') }}</h5>
                </div>

                <div class="col-12 col-md-12" v-for="item in historico" v-bind:key="item.id">
                  <div class="historico-item">
                  <div>
                    <strong>Situação:</strong> <span v-html="formatStatus(item.situacao)"></span>
                  </div>
                  <div v-if="item.situacao === 3">
                    <strong>Número Contrato:</strong> <span>{{ item.numero_contrato }}</span>
                  </div>
                  <div>
                    <strong>Observação:</strong><br/>
                    <span v-if="item.observacao" v-html="$util.nl2br(item.observacao)"></span>
                  </div>
                  <div v-if="item.files && item.files.length > 0">
                    <strong>Arquivo:</strong> <br/>
                    <ul class="files btn-link no-padding" v-for="(file, index) in item.files" :key="index" v-on:click="fileDownload(file)">
                      <div class="file-align">
                        <li class="files-item"><i class="fa fa-file"></i></li>
                        <div class="file-date">
                          <li class="files-item"> {{ file.originalFilename }}</li>
                          <li class="files-item-date">{{ $util.formatDate(file.date) }} </li>
                        </div>
                      </div>
                      <li class="files-item"><button class="btn btn-link no-padding"> <i class="ft-download"></i></button></li>
                    </ul>
                  </div>
                  <div class="mt-2 small text-right">{{ $util.formatDateTime(item.criado_em) }} - {{ item.criado_por.email }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Submit -->
        <div class="col-12">
          <button type="button" class="btn btn btn-outline-light pull-right" @click="onSave">Salvar</button>
          <button type="button" class="btn btn-link btn-sm mt-1 pull-right" @click="onCancel">Cancelar</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
// Components

// eslint-disable-next-line
import { util } from '@/utils/utils.js'

// Form
import OriginDestinationField from '@/components/Forms/OriginDestinationField'
import CustomInput from '@/components/Forms/CustomInput.vue'
import ClienteField from '@/components/Forms/ClienteField'
import MoedaField from '@/components/Forms/MoedaField'
import MercadoriaField from '@/components/Forms/MercadoriaField'
import UnidadeMedidaField from '@/components/Forms/UnidadeMedidaField'
import FrequenciaField from '@/components/Forms/FrequenciaField'
import FuncionarioField from '@/components/Forms/FuncionarioField'
import TipoCargaField from '@/components/Forms/TipoCargaField'
import CustomTextarea from '@/components/Forms/CustomTextarea.vue'

import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

// eslint-disable-next-line
import '@/assets/vendor/reflow-table/js/reflow-table.js'
import '@/assets/vendor/reflow-table/css/reflow-table.css'

// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js'
// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/locales/bootstrap-datepicker.pt-BR.min.js'
import '@/assets/vendor/bootstrap-datepicker/dist/css/bootstrap-datepicker3.min.css'

// Services
import OportunidadeService from '@/services/OportunidadeService'
import UserService from '@/services/UserService'

export default {
  name: 'OportunidadeForm',
  metaInfo () {
    return {
      titleTemplate: this.$i18n.t('oportunidades.title') + ' - %s'
    }
  },
  data () {
    return {
      isEdit: false,
      // Configuration to mount table of selected values
      customerList: [],
      modalityList: [
        {
          id: 1,
          nome: 'Aéreo'
        },
        {
          id: 2,
          nome: 'Marítimo'
        },
        {
          id: 3,
          nome: 'Rodoviário'
        }
      ],
      formData: {
        idCliente: null,
        numeroOportunidade: null,
        modalidadeProcesso: null,
        tipoCarga: null,
        dataValidade: null,
        volumeTotal: null,
        volumePotencial: null,
        target: null,
        observacao: null,
        solicitante: null,
        idorigem: null,
        iddestino: null,
        idMercadoria: null,
        idunidadeMedidaVolume: null,
        idfrequencia: null,
        idmoeda: null,
        idunidadeMedidaTarget: null
      },
      historico: [],
      formErrors: {},
      isLoading: true,
      fullPage: true,
      submitted: false
    }
  },
  components: {
    vSelect,
    OriginDestinationField,
    ClienteField,
    MoedaField,
    MercadoriaField,
    UnidadeMedidaField,
    FrequenciaField,
    FuncionarioField,
    CustomTextarea,
    TipoCargaField,
    Loading,
    CustomInput
  },
  // Apply filters to local filter
  beforeMount () {

  },
  mounted () {
    // this.$store.dispatch('SetShowFilter', true)
    // this.$store.dispatch('setShowSearch', false)
  },
  created () {
    let _this = this
    _this.isLoading = true
    let isCopy = false
    // Load basilares data

    let promises = [
      UserService.getFuncionario()
    ]

    // If is edit populate values
    let id = this.$route.params.id | this.$route.query.id

    if (this.$route.params.id) {
      _this.isEdit = true
    }

    isCopy = this.$route.query.hasOwnProperty('id')

    if (id) {
      promises.push(OportunidadeService.get(id))
      promises.push(OportunidadeService.getHistorico(id))
    }

    Promise.all(promises).then((values) => {
      // Solicitante
      let solicitante = values[0].data.data
      _this.formData.solicitante = solicitante

      // Edit Info
      if (id) {
        let oportunidade = values[1].data.data
        _this.formData = oportunidade
        _this.formData.dataValidade = new Date(oportunidade.dataValidade)

        if (isCopy) {
          _this.formData.dataValidade = null
          _this.formData.target = null
        }

        if (!isCopy) {
          _this.historico = values[2].data.data
        }
      }

      _this.isLoading = false
    }).catch(() => {
      _this.isLoading = false
    })
  },
  methods: {
    fileDownload (file) {
      this.isLoading = true
      let idOportunidade = this.$route.params.id
      OportunidadeService.getFile(idOportunidade, file.filename).then(res => {
        const url = global.window.URL.createObjectURL(new Blob([res.data]))
        const link = global.document.createElement('a')
        link.href = url
        link.setAttribute('download', file.originalFilename)
        global.document.body.appendChild(link)
        link.click()
        this.isLoading = false
      })
    },
    formatStatus (value) {
      if (!value && value !== 0) {
        return ' - '
      }

      let statusList = [
        {
          'id': 1,
          'label': this.$i18n.t('form.oportunidade.statusList.aguardando'),
          'color': '#ababab'
        },
        {
          'id': 2,
          'label': this.$i18n.t('form.oportunidade.statusList.trabalhando'),
          'color': '#ababab'
        },
        {
          'id': 3,
          'label': this.$i18n.t('form.oportunidade.statusList.disponivel'),
          'color': '#5ec65f'
        },
        {
          'id': 4,
          'label': this.$i18n.t('form.oportunidade.statusList.indisponivel'),
          'color': '#5ec65f'
        },
        {
          'id': 9,
          'label': this.$i18n.t('form.oportunidade.statusList.expirado'),
          'color': '#ababab'
        }
      ]

      let status = statusList.find(obj => {
        if (obj.id === value) {
          return obj
        }
      })

      return '<span style="color: ' + status.color + '">' + status.label + '</span>'
    },
    getOriginDestinationType (modalidadeProcesso) {
      if (modalidadeProcesso && modalidadeProcesso.id === 2) {
        // Marítimo
        return 1
      } else if (modalidadeProcesso && modalidadeProcesso.id === 1) {
        // Aéreo
        return 2
      } else if (modalidadeProcesso && modalidadeProcesso.id === 3) {
        // Aéreo
        return 3
      }
      // Padrão Maritimo
      return null
    },
    onCancel (e) {
      let _this = this

      _this.$alertSwal.modalConfirm('', 'Você tem certeza de que deseja cancelar.<br />Está certo desta ação?', 'warning', 'Sim', 'Não', (result) => {
        if (result) {
          _this.isLoading = true
          _this.$router.push({ name: 'OportunidadesIndex' })
        }
      })
    },
    displayErrors (errors) {
      // @TODO: display errors from server
      util.getFormErrors(errors)
      // let formatedErrors = util.getFormErrors(errors)
    },
    onSave (e) {
      var _this = this
      this.submitted = true
      _this.$validator.validateAll('oportunidadeForm').then(valid => {
        if (valid) {
          _this.isLoading = true

          let data = Object.assign({}, _this.formData)
          data.idCliente = data.idCliente.id
          data.idorigem = data.idorigem.id
          data.iddestino = data.iddestino.id
          data.idMercadoria = data.idMercadoria.id
          data.modalidadeProcesso = data.modalidadeProcesso.id
          data.tipoCarga = (data.tipoCarga) ? data.tipoCarga.id : null
          data.idunidadeMedidaVolume = data.idunidadeMedidaVolume.id
          data.idfrequencia = data.idfrequencia.id
          data.idmoeda = data.idmoeda.id
          data.idunidadeMedidaTarget = data.idunidadeMedidaTarget.id
          data.solicitante = data.solicitante.id
          data.volumeTotal = util.getNumberFromFormated(data.volumeTotal)
          data.volumePotencial = util.getNumberFromFormated(data.volumePotencial)
          data.target = util.getNumberFromFormated(data.target)

          delete data.numeroOportunidade
          delete data.criadoPor
          delete data.produto
          delete data.id

          let id = this.$route.params.id
          if (id) {
            OportunidadeService.put(id, data).then(response => {
              _this.$router.push({ name: 'OportunidadesIndex' })
            }).catch(err => {
              if (err.code === 400) {
                _this.displayErrors(err.errors)
              }
            }).finally(() => {
              _this.isLoading = false
            })
          } else {
            OportunidadeService.post(data).then(response => {
              _this.$router.push({ name: 'OportunidadesIndex' })
            }).catch(err => {
              if (err.code === 400) {
                _this.displayErrors(err.errors)
              }
            }).finally(() => {
              _this.isLoading = false
            })
          }
        } else {
          _this.$util.scrollToFirstError()
        }
      })
    },
    onSelectModality (val) {
      this.formData.tipoCarga = null
    }
  },
  computed: {
    showTipoCarga () {
      if (this.formData.modalidadeProcesso && this.formData.modalidadeProcesso.id === 2) {
        return true
      }

      return false
    },
    modalidadeProcessoId () {
      if (this.formData.modalidadeProcesso) {
        return this.formData.modalidadeProcesso.id
      }

      return null
    },
    tipoCargaId () {
      if (this.formData.tipoCarga) {
        return this.formData.tipoCarga.id
      }

      return null
    },
    originDestinationDisabled () {
      return !this.formData.modalidadeProcesso
    },
    unidadeMedidaDisabled () {
      // Se vazio então desabilitar
      if (!this.formData.modalidadeProcesso) {
        return true
      }

      if (this.formData.modalidadeProcesso.id === 2 && !this.formData.tipoCarga) {
        return true
      }

      return false
    }
  }
}
</script>

<style scoped>
  .swal2-popup {
    width: inherit !important;
  }

  .card-body {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .md-form-control {
    height: 30px;
  }

  .v-select.md-form-control {
    height: 35px;
  }

  .historico-item {
    padding: 10px 0;
    border-bottom: 1px #999 solid;
  }

  .files{
    list-style-type: none;
    max-width: 96%;;
    min-width: 10vw;
    margin-right: 6px;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    padding: 0 1% 0 1%;
    border: 1px solid #464646;
    border-radius: 8px;
  }

  .file-align{
    display:flex;
    align-items: center;
  }

  .files-item-date{
    font-weight: 100;
    font-size: 10px;
  }

  .file-date{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 8px;
  }

  .files-mobile{
    list-style-type: none;
    width: 93vw;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0 1% 0 1%;
    background-color: #ffffff;
    color: #000;
    border-radius: 8px;
  }
</style>
