<template>
  <div class="col-sm-4 form-group mb-4" :class="rootClassName">
    <label
      :class="[{ 'royalc-error-input': error }]" >
      {{ this.$i18n.t(label) }}
    </label>
    <v-select name="nome"
              label="nome"
              :disabled="disabled"
              :clearable="false"
              :searchable="false"
              v-model="selected"
              :options="optionList"
              @input="onSelect" :filter-by="filter" >
      <template slot="no-options" slot-scope="{searching, search }">
        <em v-if="!searching" style="opacity: 0.5;">
          Digite para buscar uma unidade de medida
        </em>
        <em v-else style="opacity: 0.5;">
          Nenhuma opção encontrada para {{ search }}
        </em>
      </template>
      <template slot="option" slot-scope="option">
        <div class="d-center">
          {{ option.nome }} {{ option.sigla && option.sigla !== option.nome ? ('(' + option.sigla + ')') : '' }}
        </div>
      </template>
      <template slot="selected-option" slot-scope="option">
        <div class="selected d-center">
          {{ option.nome }} {{ option.sigla && option.sigla !== option.nome ? ('(' + option.sigla + ')') : '' }}
        </div>
      </template>
    </v-select>
    <transition name="slide" mode="">
      <small v-show="error" class="royalc-error-field">{{ error }}</small>
    </transition>
  </div>
</template>

<script>
// vue-select
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import UnidadeMedidaService from '@/services/UnidadeMedidaService'

export default {
  inheritAttrs: false,
  name: 'TipoCargaField',
  components: {
    vSelect
  },
  created () {
    let _this = this
    _this.id = 'tipo_carga_' + _this._uid
  },
  data () {
    return {
      optionList: [],
      id: null,
      timer: 0,
      selected: null
    }
  },
  $_veeValidate: {
    // value getter
    value () {
      return this.$el.value
    },
    // name getter
    name () {
      return this.name
    }
  },
  props: {
    modalidadeProcesso: {
      type: Number,
      required: false,
      default: null
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false
    },
    delay: {
      required: false,
      type: Number,
      default: 400
    },
    value: {
      required: true
    },
    rootClassName: {
      type: String,
      required: false,
      default: ''
    },
    error: {
      type: String,
      required: false
    },
    label: {
      type: String,
      required: false,
      default: 'form.oportunidade.tipoCarga'
    }
  },
  methods: {
    /**
     * Triggered when search text changes.
     *
     * @param search  {String}   Current search text
     * @param loading {Function} Toggle loading class
     * @param loading {Function} Toggle loading class
     */
    fetchOptions (search, loading) {
      if (search.length > 0) {
        window.clearTimeout(this.timer)

        this.timer = window.setTimeout(function () {
          loading(true)
          loading(false)
        }, this.delay)
      }
    },
    fetchAutoLoad (search) {
      let _this = this

      let filters = {
        'searchTerm': search
      }

      UnidadeMedidaService.getUnidadesMedidas(filters).then(res => {
        _this.optionList = res.data.data
      })
    },
    onSelect (val) {
      this.$emit('input', val)
    },
    filter (option, label, search) {
      let temp = search.toLowerCase()

      return ((option.nome && option.nome.toLowerCase().indexOf(temp) > -1) || (option.sigla && option.sigla.toLowerCase().indexOf(temp) > -1))
    }
  },
  watch: {
    value: function (newValue) {
      this.selected = newValue
    },
    modalidadeProcesso: function (newValue, oldValue) {
      let _this = this

      if (oldValue !== null) {
        _this.selected = null
      }

      if (newValue === 2) {
        _this.optionList = [
          {
            id: 3,
            nome: 'FCL'
          },
          {
            id: 4,
            nome: 'LCL'
          }
        ]
      } else {
        _this.optionList = []
      }
    }
  }
}
</script>
